:root {
  --bcs-pink: #ED1F82;
  --bcs-light-pink: #FFE1EF;
  --bcs-indigo: #161041;
  --bcs-purple: #6B4FA0;
  --bcs-blue: #00B7D1;
  --bcs-blue-light: #C2E4E9;
  --bcs-yellow: #FDE14B;
  --bcs-gray: #ced4da;
  --bcs-disabled-gray: #B7B7B7;
  --bcs-white: #FFFFFF;
  --bcs-black: #000000;
  --bcs-column-header: #F7F8FA;
  --bcs-object-line-highlighted: #AFABAC;
  --bcs-object-line: #C2C7CB;
  --bcs-icon-gray-disabled: #757575;
  --bcs-light-gray: rgba(222, 222, 222, 0.5);
  --bcs-dark-gray: #8C8C8C;
  --bcs-ocr-glow: #DE41A4;
  --bcs-background-color: #FBFBFB;
  --bcs-top-panel-color: #F7F7F7;
  --bcs-bottom-band-color: #929FAC;
  --bcs-font-gray: #707070;
  --bcs-non-compliant-red: #FF7F7F;
  --bcs-compliant-green: #07DB7F;
  --bcs-yellow-dark: #DEBC06;
  --bcs-light-black: #1D1D1D;
  --bcs-input-border: #9A9A9A;
  --bcs-placeholder-text: #666666;

}
