@import "assetsClass";

.mask-image {
  background-image: none;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
}

.menu-item.active .menu-icon  {
  background-color: var(--bcs-primary-color) !important;
}



.activity-item-container.PendingStatus .phx-icon {
  background-color: var(--bcs-blue) !important;
}
.CompleteStatus .phx-icon {
  background-color: var(--bcs-dark-gray) !important;
}


.menu-item .menu-icon {
  background-color: var(--bcs-primary-background-color);
}

.modal-btn {

  &.email-icon {
    background-color: var(--bcs-blue);
    &:hover {
      background-color: var(--bcs-light-blue);
      .icon-email {
        background-color: var(--bcs-blue) !important;
      }
    }
  }

  &.task-icon {
    background-color: var(--bcs-primary-color);
    &:hover {
      background-color: var(--bcs-primary-highlight-color);
      .icon-task {
        background-color: var(--bcs-primary-color) !important;
      }
    }
  }

  &.call-icon {
    background-color: var(--bcs-yellow);
    &:hover {
      background-color: var(--bcs-light-yellow);
      .icon-logged-call {
        background-color: var(--bcs-yellow) !important;
      }
    }
  }

  &.note-icon {
    background-color: var(--bcs-purple);
    &:hover {
      background-color: var(--bcs-light-purple);
      .icon-note {
        background-color: var(--bcs-purple) !important;
      }
    }
  }
}


.activity-item-container {
  .phx-icon {
    background-color: var(--bcs-dark-gray) !important;
  }
}
.phx-button {
  &.primary .phx-icon {
    background-color: var(--bcs-primary-background-color);
  }
  &.secondary .phx-icon {
    background-color: var(--bcs-primary-color);
  }
  &.tertiary .phx-icon {
    background-color: var(--bcs-primary-background-color);
  }
  &.light-blue .phx-icon {
    background-color: var(--bcs-primary-background-color);
  }
}


.phx-button:hover {
  &.primary .phx-icon {
    background-color: var(--bcs-primary-color) !important;
  }
  &.tertiary .phx-icon {
    background-color: var(--bcs-disabled-icon-color) !important;
  }
  &.light-blue .phx-icon {
    background-color: var(--bcs-blue) !important;
  }

  &.primary fa-icon {
    color: var(--bcs-primary-color) !important
  }
  &.secondary fa-icon {
    color: var(--bcs-primary-color) !important;
  }
  &.tertiary fa-icon {
    color: var(--bcs-disabled-icon-color) !important
  }
  &.light-blue fa-icon {
    color: var(--bcs-blue) !important
  }
}


.phx-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;

  &:hover {
    background-color: var(--bcs-primary-background-color);
    border-radius: 30px;

    .phx-icon {
      background-color: var(--bcs-primary-color) !important;
    }

    .phx-fa-icon {
      color: var(--bcs-primary-color) !important;
    }
  }
}


.phx-edit-icon {
  width: 15px;
  height: 15px;
}



.image-user-management {
  width: 25px;
  height: 25px;
}

.image-circle-white-tick {
  width: 15px !important;
  height: 15px !important;
}

.image-attachment-paperclip {
  width: 18px;
  height: 18px;
  transform: rotate(180deg);
}


.image-attachment-paperclip-white {
  background-color: var(--bcs-primary-background-color);
  transform: rotate(180deg);
}

.icon-cancel-white {
  background-color: var(--bcs-primary-background-color);
}



.image-chat-message-file {
  width: 70px;
  height: 70px;
  transform: scaleX(-1);
}




.icon-cross {
  background-color: var(--bcs-non-compliant-red);
}

.image-compliant {
  height: 23px;
  width: 23px;
}

.image-compliant-amber {
  height:23px;
  width:23px;
}

.image-filter-white {
  width: 30px;
  height: 15px;
  transform: rotate(180deg);
  cursor: pointer;
}

.image-job-compliance-documents {
  transform: scaleX(-1);
}



.image-location-marker-orange {
  width: 13px;
  height: 17px;
}

.image-like-button-white {
  width: 20px;
  height: 20px;
}


.icon-cancel-white {
  background-color: var(--bcs-primary-color) !important;
}

.icon-send-icon {
  background-color: var(--bcs-primary-background-color);
}

.image-document-upload {
  width: 54px;
  height: 58px;
}

.image-notification-bell {
  width: 32px;
  height: 34px;
}

.image-vendor-upload {
  width: 54px;
  height: 58px;
}

.image-new-review {
  @extend .mask-image;
  background-color: var(--bcs-primary-background-color);
}


.icon-gps-mark {
  background-color: var(--bcs-default-icon-color);
}


.image-location-icon {
  width: 20px;
  height: 20px;
}

.icon-message-white {
  background-color: var(--bcs-primary-background-color);
}

.image-non-compliant {
  height: 23px;
  width: 23px;
}


.icon-add-vendor {
  background-color: var(--bcs-default-icon-color);
}

.icon-send-white {
  background-color: var(--bcs-primary-background-color);
}

.image-new-review {
  width: 25px;
  height: 23px;
}

.image-locations-add {
  width: 25px;
}


.image-request-review {
  background-size: contain;
}

.image-add-media {
  background-size: contain;
}

.icon-properties-vendors {
  background-color: var(--bcs-default-icon-color);
}


.icon-properties-jobs {
  background-color: var(--bcs-default-icon-color);
}
.icon-ellipses {
  background-color: var(--bcs-dark-gray);
}

.icon-properties-leases {
  background-color: var(--bcs-default-icon-color);
}

.icon-properties-tenants {
  background-color: var(--bcs-default-icon-color);
}

.icon-message-icon {
  background-color: var(--bcs-light-gray);
}

.icon-phone-icon {
  background-color: var(--bcs-light-gray);
}

.icon-website-icon {
  background-color: var(--bcs-light-gray);
}

.image-share {
  background-size: contain;
}

.icon-chat-icon {
  background-color: var(--bcs-light-gray);
}







.icon-jobposting {
  background-color: var(--bcs-primary-background-color);
}




.image-calendar-black {
  background-size: contain;
  background-repeat: no-repeat;
}

.image-client-connections {
  background-size: contain;
  background-repeat: no-repeat;
}



.icon-calendar {
  background-color: var(--bcs-dark-gray);
}

.icon-eye {
  background-color: var(--bcs-dark-gray);
}


.image-profile-info {
  width: 25px;
  height: 25px;
  background-size: cover;
}

.icon-add {
  background-color: var(--bcs-primary-background-color);
}


.icon-user-plus-white {
  background-color: var(--bcs-primary-background-color);
}

.icon-review-document-icon {
  background-color: var(--bcs-primary-background-color);
}

.icon-document-download-icon {
  background-color: var(--bcs-primary-color);
}










