.modal-xl {
  max-width: 1400px;
}

.modal-lg {
  width: 1000px;
}

.modal-full-wide {
  min-width: 100%;
}

.modal-smedium{
  max-width: 400px;
}


.modal-md {
  max-width: 577px;
}

.modal-lm {
  width: 300px;
}

.modal {
  z-index: 1030;
}

.modal-backdrop {
  z-index: 1000;
}

.modal-auto-width.modal-dialog {
  position: relative;
  display: table; /* This is important */
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  min-width: 300px;
}

.modal-header {
  background-color: #161041;
  color: var(--bcs-white);
  font-size:24px;
  span {
    color: var(--bcs-white);
  }
}
.modal-body {
  font-size:14px;
}

.wide-modal .modal-content {
  width: 60vw;
}

@media screen and (max-width: 800px) {
  .wide-modal .modal-content {
    width: 80vw;
  }
}

.add-policy-modal {
  margin: 10px;
  border-radius: 7px;
  background-color: var(--bcs-white);
  font-weight: 500;
  max-height: 35rem !important;
  min-height: 30rem !important;
  min-width: 20rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal-buttons-top-right {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreen-loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

