// THIS FILE IS AUTO-GENERATED IN populateEnum.js
    .mask-image {
      background-image: none;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-size: contain;
      -webkit-mask-size: contain;
      mask-position: center;
      -webkit-mask-position: center;
    }

    @mixin mask-icon-style($imageUrl) {
      @extend .mask-image;
      mask-image: url('../assets/images/#{$imageUrl}');
      -webkit-mask-image: url('../assets/images/#{$imageUrl}');
    }

    @mixin image-style($imageUrl) {
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../assets/images/#{$imageUrl}');
    }

