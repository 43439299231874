:root {
  // Direct Colors
  --bcs-yellow: #FDE14B;
  --bcs-light-yellow: #fff7cc;
  --bcs-light-gray: #9A9A9A;
  --bcs-dark-gray: #8C8C8C;
  --bcs-blue: #00B7D1;
  --bcs-light-blue: #C2E4E9;
  --bcs-sky-blue: #0d84e4;
  --bcs-purple: #6B4FA0;
  --bcs-light-purple: #E7DAFF;
  --bcs-violet: #6876E7;
  --bcs-navy-blue: #0b507b;
  --bcs-brown: #EB952F33;
  --bcs-dark-red: #FF7F7F33;
  --bcs-green: #3dc6a8;
  --bcs-light-green: #CAF5E2;
  --bcs-dark-green: #07DB7F33;
  --bcs-peach: #fbe6cf;
  --bcs-orange: #fd7e14;

  // BCS Colors
  --bcs-primary-color: #ED1F82;
  --bcs-primary-highlight-color: #FFE1EF;
  --bcs-secondary-color: #161041;
  --bcs-secondary-highlight-color: #E2E2E22E;

  // Text Colors
  --bcs-primary-text-color: #1D1D1D;
  --bcs-secondary-text-color: #f3f3f3;
  --bcs-tertiary-text-color: #707070;
  --bcs-disabled-text-color: #9A9A9A;
  --bcs-riskbot-text-color: #00243A;

  // Separator Line Colors
  --bcs-separator-line: #C2C7CB;
  --bcs-separator-line-highlighted: #AFABAC;

  // Border Colors
  --bcs-input-border-color: #9A9A9A; // Not just used for input border
  --bcs-lighter-border-color: #cbcbcb;
  --bcs-placeholder-text-color: #666666;

  // Compliance Colors
  --bcs-compliant-green: #07DB7F;
  --bcs-non-compliant-red: #FF7F7F;

  // Background Colors
  --bcs-primary-background-color: #FFF;
  --bcs-secondary-background-color: #E2E2E22E;
  --bcs-tertiary-background-color: #b1b1b1;
  --bcs-hover-background-color: #F7F8FA;
  --bcs-disabled-background-color: #f5f5f5;

  // Box Shadow Colors
  --bcs-boxshadow-color: #00000059;

  // Others
  --bcs-rating-color: #DEBC06;
  --bcs-rating-outline-color: #EB952F;
  --bcs-default-icon-color: #000;
  --bcs-disabled-icon-color: #757575;
}

.bcs-dark-theme {

}

.lawrence-theme {

}
