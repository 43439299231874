@import "icons";
@import 'themes';
@import 'modal';

body {
  font-family: 'Albert Sans', serif;
  margin: 0;
  background: var(--bcs-primary-background-color);

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--bcs-primary-color);
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--bcs-primary-color);
  }
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
}

.container-title {
  font-weight: 500;
  font-size: 18px;
}

hr {
  margin-top: 3px;
  margin-bottom: 0;
}

.bottom-container {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1050;

  div.alert-container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    z-index: 1050;
  }
}

.alert-info {
  color: var(--bcs-navy-blue);
  background-color: var(--bcs-light-blue);
  border-color: var(--bcs-light-blue);
}

label {
  display: inline-block;
  margin-bottom: 0.1rem;
  font-weight: 400;
}


.main-container {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--bcs-primary-background-color);

  .main-content-vendor {
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

  .main-content {
    position: absolute;
    flex: 1; /* Make it take up all available space */
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

  #bcs-global-chat-bot-container {
    pointer-events: none;
    position: relative;
    display: flex;
    justify-content: flex-end;
    right: -422px;
    height: 100%;
    width: 100%;
    transition: right 0.3s ease-in-out;
    z-index: 1000;

    &.chat-box-visible {
      right: 0;
    }
  }
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box-container {
  background-color: var(--bcs-primary-background-color);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.box-shadow {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.main-content-padding {
  padding: 24px;
}

.item-container {
  position: relative;
  background-color: var(--bcs-primary-background-color);
  border: 1px solid var(--bcs-lighter-border-color);
  border-radius: 5px;
  margin: auto;
  padding: 15px;

  &.full {
    min-height: calc(100% - 48px);
    margin: 24px 24px;
  }

  &.wrapped {
    margin: 24px 24px;
  }
}

.oauto {
  overflow: auto;
}

.ounset {
  overflow: unset;
}

.ohidden {
  overflow: hidden;
}

.bold {
  font-weight: 500;
}

.bolder {
  font-weight: 600;
}

.border {
  border: solid 1px #595757;
}

.hand {
  cursor: pointer;
}

.fs-12 {
  font-size: 12px;
  line-height: 12px;
}

.fs-13 {
  font-size: 13px;
  line-height: 13px;
}

.fs-14 {
  font-size: 14px;
  line-height: 14px;
}

.fs-15 {
  font-size: 15px;
  line-height: 15px;
}

.fs-16 {
  font-size: 16px;
  line-height: 16px;
}

.fs-18 {
  font-size: 18px;
  line-height: 18px;
}

.fs-20 {
  font-size: 20px;
  line-height: 20px;
}

.fs-24 {
  font-size: 24px;
  line-height: 24px;
}

.fs-25 {
  font-size: 25px;
  line-height: 25px;
}

.topbar-tabs {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
}

.topbar-tabs-vendor {
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
  padding: 0 10px;
  background-color: var(--bcs-tertiary-background-color);
}


.phx-tab, .phx-tab-container {
  min-width: max-content;
  display: flex;
  justify-content: center;
}

.phx-tab-container {
  font-size: 16px;
  background-color: var(--bcs-disabled-icon-color);
  height: 38px;
  flex-direction: row;
  border-radius: 5px;
  padding: 20px 10px;
  margin: 0 5px 0 0;
}

.phx-tab {
  align-items: center;
  color: var(--bcs-secondary-text-color);
  cursor: pointer;
  outline: none;
  text-align: center;
  font-weight: lighter;
  padding: 0 10px;

  &.small {
    height: 20px;
    border-bottom: 2px solid;
    padding: 0 3px;
  }

  &.active {
    align-self: center;
    padding: 1px 10px;
    border: 1px solid var(--bcs-lighter-border-color);
    border-radius: 5px;
    background-color: var(--bcs-secondary-background-color);
  }
}

.more-options {
  padding: 8px 19px 8px 19px;
  min-width: 100px;
}

.more-options:hover {
  cursor: pointer;
  background-color: var(--bcs-hover-background-color);
}

.nav-tabs {
  display: flex;
  border-bottom: 1px solid #C2C7CB;

  .nav-link {
    padding-bottom: 0;
    border: 0;
  }

  .nav-item {
    margin: 0;
    width: 13%;
    border-bottom: 5px solid #939fa6;

    &.active {
      border-bottom: 5px solid var(--bcs-primary-color);

      .phx-category-tab {
        color: var(--bcs-primary-color);
        font-size: 16px;
      }
    }

    &:hover {
      cursor: pointer;
      border-bottom: 5px solid var(--bcs-primary-color);

      .phx-category-tab {
        color: var(--bcs-primary-color);
      }
    }
  }
}

input {
  &.ng-invalid.ng-touched {
    border-color: red;
  }
}

phx-select.ng-dirty.ng-invalid {
  .input-group {
    border: 1px solid red;
  }
}

location-typeahead.ng-dirty.ng-invalid {
  input {
    border-color: red;
  }
}

location-typeahead.ng-touched.ng-invalid {
  input {
    border-color: red;
  }
}

.ng-dirty.ng-invalid {
  border-color: red;
}

.ng-invalid.ng-touched {
  border-color: red;
}

.ngx-slider-pointer {
  background-color: var(--bcs-sky-blue) !important;

  &.active::after {
    background-color: var(--bcs-sky-blue) !important;
  }
}


.service-tooltip .tooltip-inner {
  background-color: var(--bcs-navy-blue) !important;
  text-decoration: underline;
  max-height: 400px;
  overflow: hidden;
}

.tooltip-arrow.arrow:before {
  border-top-color: var(--bcs-input-border-color) !important;
  border-bottom-color: var(--bcs-input-border-color) !important;
}


.page-title {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-size: 24px;
  font-weight: bolder;
  color: var(--bcs-primary-text-color);
}

.page-item.active .page-link {
  background-color: var(--bcs-blue);
  border-color: var(--bcs-blue);
}

.page-link {
  color: var(--bcs-blue);
}

.no-hover-underline {
  &:hover {
    text-decoration: none !important;
  }
}

.pagination > li {
  padding: 0 !important;
}

.bs-rating-star {
  font-weight: bold;
  margin-right: 2px;
  color: var(--bcs-rating-color);
}

rating.rating-component span:focus {
  outline: none !important;
  box-shadow: none !important;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.drag-drop-container {
  display: flex;
  justify-content: center;
}

.cdk-drag-preview {
  z-index: 100000 !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.drag-drop-list.cdk-drop-list-dragging .drag-drop-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-placeholder {
  background: var(--bcs-tertiary-background-color);
  border: dotted 3px var(--bcs-input-border-color);
  min-height: 40px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


.phx-ng-select.phx-ng-select {
  box-shadow: none !important;
  outline: none !important;
  height: 26px;
  min-height: 26px;
  width: 202px;
  font-size: 14px;
  color: var(--bcs-primary-text-color);

  .ng-select-container {
    height: 26px;
    min-height: 26px;
    width: 202px;
    border: 1px solid #9A9A9A !important;

    .ng-placeholder {
      color: var(--bcs-placeholder-text-color);
    }
  }
}

input:focus {
  outline: none;
  box-shadow: 0 0 4px var(--bcs-blue);
}

.phx-ng-select input:focus {
  outline: none;
}

/* removes white outline when you click close on modals */
button.close:focus {
  outline: none;
}

@media screen and (max-width: 1450px) {
  .topbar-tabs {
    font-size: 15px;
  }
}

@media screen and (max-width: 1250px) {
  .topbar-tabs {
    font-size: 14px;
  }
}

.popover-body {
  padding: 7px 0;
}


.my-vendor-list-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .nav-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px;
    margin-right: 25px;

    .tab-content {
      justify-content: center;
      color: var(--bcs-primary-text-color);
      border: none;

      .svg {
        width: 13px;
        height: 17px;
      }

    }
  }


  .my-vendor-list-heading, .my-tenant-list-heading {
    &.active {
      color: var(--bcs-primary-color);
      border-bottom: 6px solid var(--bcs-primary-color);
    }

    &:hover {
      color: var(--bcs-primary-color);
      border-bottom: 6px solid var(--bcs-primary-color);
      cursor: pointer;
    }

    .tab-content {
      display: flex;
      justify-content: center;
      padding: 4px 20px 5px 20px;
      margin: 0;
      font-size: 12px;

    }
  }
}


.vendor-list-container {
  padding: 24px 24px;
  position: relative;
  background-color: var(--bcs-primary-background-color);
}

input[type="radio"] {
  width: 20px;
  height: 20px;
}

input[type="radio"]:checked:before {
  content: "";
  display: block;
  position: relative;
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border-color: gray !important;
  background: var(--bcs-primary-color) !important;
}

input[type="radio"]:disabled:before {
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  background: gray !important;
}

input[type="date"] {
  border-radius: 3px;
  border: 1px solid #9A9A9A;
  padding: 0.2rem 0.5rem;
}


::-webkit-calendar-picker-indicator {
  background-color: var(--bcs-primary-background-color);
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}


.move-frame {
  padding-top: 200px;
}


phoenix-maps {
  width: 100%;
  height: 100%;
}

.no-hover-underline {
  &:hover {
    text-decoration: none !important;
  }
}


.requirement-category-body {
  background-color: var(--bcs-primary-background-color);
  padding: 1px;
  border-bottom: 1px solid #d5d5d5;
  min-height: 400px;
}

.requirement-form {
  margin: 15px;
  padding: 5px 15px;
  border-radius: 7px;
  background-color: var(--bcs-primary-background-color);
  border: 1px solid var(--bcs-lighter-border-color);
  overflow-x: hidden;
  color: var(--bcs-primary-text-color);
  min-height: 360px;
}

.requirement-header {
  font-size: 14px;
  font-weight: 700;
}

.requirement-box {
  padding: 10px;
}

.fw-700 {
  font-weight: 700;
}

.requirement-link {
  color: var(--bcs-blue);
  text-decoration: underline;
  line-height: 14px;
  font-size: 14px;

  a {
    font-weight: 700;

    &:hover {
      cursor: pointer;
      font-weight: 900;
    }

    color: inherit;
  }
}

.validator-error {
  color: red;
}

.popup {
  z-index: 1000;
  position: absolute;
  display: none;
  background-color: var(--bcs-primary-background-color);
  padding: 10px;
  top: 63px;
  min-width: 249px;
  max-width: fit-content;
  height: fit-content;
  max-height: 500px;
  background: var(--bcs-primary-background-color);
  box-shadow: 0 3px 6px var(--bcs-boxshadow-color);
  border-radius: 3px;
  opacity: 1;
  overflow-y: auto;

  .checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 0;
  }

  .separator {
    margin-right: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: .5px solid var(--bcs-separator-line);
  }

  input {
    pointer-events: all;
    width: 18px;
    height: 18px;
    background: var(--bcs-primary-background-color);
    box-shadow: 0 3px 6px var(--bcs-boxshadow-color);
    border: 1px solid var(--bcs-input-border-color);
    border-radius: 3px;
    opacity: 1;
    margin-right: 23px;
  }

  .select-all-label {
    font-weight: bolder;
  }

  label {
    text-align: left;
    font-size: 14px/17px;
    letter-spacing: 0;
    color: var(--bcs-primary-text-color);
    opacity: 1;
    margin-bottom: 0 !important;
  }
}

.popup.active {
  display: block !important;
}

input.pink-checkbox {
  accent-color: var(--bcs-primary-color);
  width: 20px;
  height: 20px;
}

input.pink-checkbox:focus {
  outline: none;
}


.link {
  color: var(--bcs-blue);
  cursor: pointer;
  text-decoration: underline;
  font-style: normal;
  font-weight: 700;

  &:hover {
    font-weight: 900;
  }

  &.no-underline {
    text-decoration: none;
  }
}

.bcs-link {
  color: var(--bcs-blue);
  cursor: pointer;
  font-weight: bold;

  &:hover {
    font-weight: 700;
    text-decoration: underline;
  }
}

.clear-filters-btn {
  display: flex;
  align-self: center;
  padding-left: 33px;
}

.clear-filter-text {
  font-weight: lighter;
  margin-left: 6px;
}

.clear-icon {
  width: 13px;
  height: 13px;
}

.settings-menu-item {
  width: 100%;
}

.side-tab-settings {
  max-width: 186px;
  border-right: 1px solid #ebebeb;
  font-weight: 500;
  padding-top: 10px;
  box-sizing: border-box;

  div {

    &:not(.active) {
      margin-left: 5px;
    }

    &.active {
      border-left: 5px solid var(--bcs-primary-color);
      border-spacing: 4px;

      a {
        color: var(--bcs-primary-color) !important;
      }
    }

    a {
      font-family: Albert Sans, sans-serif;
      font-weight: bold;
      padding-left: 15px;
      color: var(--bcs-primary-text-color);
      text-decoration: none;
      font-size: 14px;
      width: 100%;
      height: 38px;
    }
  }
}

.sub-settings-menu-item-container {
  padding-top: 10px;

  a.sub-setting {
    padding: 10px 10px;
    font-size: 14px;
    background-color: var(--bcs-primary-background-color);
    text-decoration: none;
    color: var(--bcs-primary-text-color);
    height: 38px;

    &:hover {
      background-color: var(--bcs-hover-background-color);
      color: var(--bcs-primary-color);
    }

    &.active {
      font-weight: 700;
      color: var(--bcs-primary-color);
      background-color: var(--bcs-primary-highlight-color);
    }
  }
}

.sub-side-tab-settings {
  max-width: 176px;
  width: 176px;
}

.settings-container {
  padding: 20px;
}

.section-title {
  font-weight: bold;
  font-size: 14px;
}

phx-button fa-icon {
  color: var(--bcs-primary-background-color);
}

phx-select.ng-touched.ng-invalid {
  .input-group {
    border: 1px solid red !important;
  }
}

.ml-100 {
  margin-left: 100px;
}

.phx-accordion {
  background: var(--bcs-primary-background-color);
  font-weight: 500;

  .panel {
    &.panel-open {

      .card {
        box-shadow: 0 0 5px #E0E2E4;
      }

      .card-header {
        font-weight: bold;


        .triangle {
          transform: rotate(0deg);
          transition-duration: .3s;
        }

        .btn-link {
          font-weight: bold;
        }
      }
    }
  }

  .panel-body {
    margin: 0 15px 15px 15px;
    border-top: 1px solid #E0E2E4;
  }

  .card {
    border: 1px solid var(--bcs-input-border-color);
  }

  .card-header {
    background: var(--bcs-primary-background-color);
    color: var(--bcs-primary-text-color);
    text-decoration: none;
    padding: 0px;
    font-size: 16px;
    border-bottom: 0px;
    border-radius: .25rem;
    font-weight: normal;

    .triangle {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 7.5px 9px 7.5px;
      border-color: transparent transparent var(--bcs-input-border-color) transparent;
      transform: rotate(180deg);
      transition-duration: .3s;

    }

    .btn-link {
      font-size: 14px;
      color: var(--bcs-primary-text-color);
      text-decoration: none;
    }
  }
}

/* replaced when bootstrap version updated */
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}


.phx-modal {
  .modal-content {
    border-radius: .5rem;
    max-height: calc(100vh - 20px);
  }

  &.modal-dialog {
    margin: 10px auto;
    color: var(--bcs-primary-text-color);
    font-weight: 500;
  }

  &.modal-smd {
    max-width: 425px;
  }

  .modal-body {
    padding: 25px;
    overflow-y: auto;
    max-height: calc(100vh - 190px);
  }

  .modal-header {
    &.light {
      font-weight: 700;
      font-size: 24px;
      border-bottom: solid;
      border-width: 1.2px;
      border-color: var(--bcs-input-border-color);
      margin-left: 1rem;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }

    button.close {
      opacity: 1;
      padding: 24px;
    }

    button.close:hover {
      opacity: .75;
    }
  }


  .modal-footer {
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .btn-container {
      display: flex;

      :nth-child(n) {
      }
    }
  }
}

.separator-text {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator-text::before,
.separator-text::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--bcs-separator-line);
}

.separator-text:not(:empty)::before {
  margin-right: .25em;
}

.separator-text:not(:empty)::after {
  margin-left: .25em;
}

input[type=date]::-webkit-calendar-picker-indicator {
  padding-left: 10px;
  padding-right: 10px;
}

.phx-split-actionbar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: center !important;
  padding-left: 40px;
  padding-right: 40px;
}

.phx-input {
  height: 24px;
  width: 100%;
  padding-left: 10px;
  border: 1px solid #9A9A9A;
  border-radius: 3px;


  &.w-100 {
    height: 32px;
    width: 100%;
  }

  &.medium {
    height: 32px;
    width: 170px;
  }

  &.large {
    height: 32px;
    width: 236px;
  }

  &.xlarge {
    height: 38px;
    width: 280px;
    border: 1px solid #aeaeae;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 4px var(--bcs-blue);
  }

  &::placeholder {
    color: var(--bcs-placeholder-text-color);
  }
}

.phx-input:disabled {
  color: var(--bcs-disabled-text-color);
}

.height-medium {
  height: 30px;
}

.height-small {
  height: 26px;
}

.clickable {
  cursor: pointer;
}

/* need this selector for appendTo ng-selects */
.ng-dropdown-panel.phx-dropdown .ng-dropdown-panel-items {
  .ng-option.ng-option-marked {
    background-color: var(--bcs-primary-background-color);
  }

  .ng-option.ng-option-selected {
    background-color: var(--bcs-primary-highlight-color);
  }
}


.ng-select.phx-dropdown {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: var(--bcs-primary-highlight-color);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: var(--bcs-primary-background-color);
  }

  .ng-select-bottom {
    margin-top: 3px;
    width: 300px;
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: var(--bcs-input-border-color);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .ng-select-focused .ng-select-container {
    border-color: var(--bcs-blue);
    -webkit-box-shadow: 0px 0px 4px 0px var(--bcs-blue);
    -moz-box-shadow: 0px 0px 4px 0px var(--bcs-blue);
    box-shadow: 0px 0px 4px 0px var(--bcs-blue);
  }

  &.ng-select-opened .ng-select-container {
    border-color: var(--bcs-blue);
    -webkit-box-shadow: 0px 0px 4px 0px var(--bcs-blue);
    -moz-box-shadow: 0px 0px 4px 0px var(--bcs-blue);
    box-shadow: 0px 0px 4px 0px var(--bcs-blue);
  }


  .ng-select-container {
    width: 100%;
    max-width: 500px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    border: 1px solid #9A9A9A;
    border-radius: 3px;
    height: 100%;

    .ng-value-container {
      .ng-placeholder {
        color: var(--bcs-placeholder-text-color);
      }
    }

  }

  &.w-100 .ng-select-container {
    min-height: 32px;
    width: 100%;
    max-width: 100%;
  }

  &.medium .ng-select-container {
    min-height: 32px;
    height: 30px;
    width: 170px;
  }

  &.large .ng-select-container {
    min-height: 32px;
    height: 32px;
    width: 236px;
  }

  &.xlarge .ng-select-container {
    height: 38px;
    border: 1px solid #aeaeae;
  }

  .ng-clear-wrapper {
    color: var(--bcs-primary-text-color);
  }

  .ng-clear-wrapper:hover .ng-clear {
    color: var(--bcs-primary-color);
  }

  .ng-arrow-wrapper {

  }

  .ng-arrow {
    border-color: var(--bcs-input-border-color) transparent transparent;
  }

  .dropdown-container {


    &.medium {
      height: 30px;
      width: 170px;
    }

    &.large {
      height: 30px;
      width: 236px;
    }


    .selected-item-container {
      flex: 4;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      overflow-x: hidden;
      overflow-y: hidden;
      scrollbar-width: none;
      color: var(--bcs-primary-text-color);

      .dropdown-input {
        border-radius: 3px 0 0 3px;
        height: 28px;
        flex: 1;
        padding: 2px 0;
        border: none;
        margin: 1px;
        text-indent: 10px;

        &::placeholder {
          color: var(--bcs-placeholder-text-color);
        }
      }

      &:hover {
        overflow-x: auto;
      }

      .selected-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 10px;
        margin: 4px 4px 4px;
        font-size: 14px;
        width: 100%;

        &:hover {
          opacity: .8;
        }

        .item-name {
          font-weight: normal;
        }

        .close-btn {
          font-weight: bolder;
        }
      }
    }

    .arrow-container {
      flex-basis: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .arrow {
      display: flex;
      background-color: var(--bcs-default-icon-color);
      align-items: center;
      justify-content: center;
      height: 8.23px;
      width: 11.66px;
      margin: 0px 7px;
    }
  }

  .dropdown-content {
    max-width: 500px;

    z-index: 1000;
    position: absolute;
    color: var(--bcs-primary-text-color);
    background-color: var(--bcs-primary-background-color);
    box-shadow: 0px 6px 9px var(--bcs-boxshadow-color);
    max-height: 300px;
    min-height: fit-content;
    width: 93.5%;
    margin-top: 5px;
    border-radius: 6px;
    border: 1px solid var(--bcs-input-border-color);
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;

    .no-option {
      font-weight: lighter;
      padding: 10px;
    }

    .option {
      font-weight: lighter;
      padding: 10px;

      &:hover {
        cursor: pointer;
        background-color: var(--bcs-primary-highlight-color);
      }

      &:focus {
        background-color: var(--bcs-primary-highlight-color);
        border: 2px solid var(--bcs-primary-color);
        border-radius: 6px;
        outline: none !important;
      }
    }

    input[type="text"] {
      padding: 10px;
      padding-left: 34px;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 90%;
      border-radius: 6px;
      border: 1px solid var(--bcs-input-border-color);
      justify-content: center;
    }

    .image-search {
      position: absolute;
      top: 23px;
      left: 32px;
    }
  }
}

.pl-10p {
  padding-left: 10px;
}

.pr-10p {
  padding-right: 10px;
}

.pt-10p {
  padding-top: 10px;

}

.pb-10p {
  padding-bottom: 10px;
}

.p-10p {
  padding: 10px;
}

.ml-10p {
  margin-left: 10px;
}

.mr-10p {
  margin-right: 10px;
}

.mt-10p {
  margin-top: 10px;

}

.mb-10p {
  margin-bottom: 10px;
}

.m-10p {
  margin: 10px;
}

.w-75p {
  width: 75px;
}

.w-100p {
  width: 100px;
}

.empty-state-box {
  text-wrap: pretty;
  padding: 0px 40px;
  height: 90px;
  width: 240px;
  background-color: var(--bcs-primary-background-color);
  color: var(--bcs-dark-gray);
  border: 1px solid var(--bcs-separator-line);
  font-size: 12px;
  align-content: center;
  border-radius: 0.3rem;
}

.pink-text {
  color: var(--bcs-primary-color) !important;
}

.blue-text {
  color: var(--bcs-blue) !important;
}

.non-compliant-text {
  color: var(--bcs-non-compliant-red);
}

.disabled {
  pointer-events: none;
  color: var(--bcs-disabled-text-color);
}

.information-modal-nav-link {
  display: inline;
  color: var(--bcs-blue) !important;
}
